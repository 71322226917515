@import '../abstracts/variables';
@import '../abstracts/mixins';

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $color-blue-primary;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $color-blue-primary, 0 0 5px $color-blue-primary;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}
