@import '../abstracts/variables';
@import '../abstracts/mixins';

.mantine-Drawer-root {
  position: relative !important;
  z-index: 999999 !important;
}

.mantine-Drawer-header {
  padding: 0 !important;
  position: inherit !important;
}

.mantine-Tooltip-tooltip {
  padding: 1rem !important;
  font-size: 1.2rem !important;

  @include respond(tab-port) {
    padding: 1.4rem !important;
    font-size: 1.6rem !important;
  }
}

.productAccordionInformation .mantine-1avyp1d:last-child {
  border-bottom: 1px solid $color-lightgrey !important;
}

.productAccordionInformation {
  max-width: 100%;
}

.mantine-Accordion-label {
  padding-block: 1.75rem !important;

  @include respond(tab-port) {
    padding-block: 2rem !important;
    font-size: 2rem !important;
    line-height: 1.7rem !important;
  }
}

.mantine-Accordion-content {
  padding-bottom: 2.4rem !important;
  padding-left: 0;
  padding-right: 0;

  @include respond(tab-port) {
    font-size: 1.75rem !important;
    line-height: 2.5rem !important;
  }
}

.mantine-Accordion-control {
  &[data-active] .mantine-Accordion-label {
    color: $color-blue-primary !important;
    font-weight: 600 !important;
  }
  padding: 0;
}

#forBuyers,
#forSellers {
  .mantine-Accordion-control {
    &[data-active] .mantine-Accordion-label {
      color: $color-dark-grey-secondary !important;
      font-weight: 600 !important;
    }
  }

  .mantine-Accordion-label {
    padding-block: 2.4rem !important;

    @include respond(tab-port) {
      font-size: 1.75rem !important;
      line-height: 2.4rem !important;
    }
  }

  .mantine-Accordion-content {
    padding-bottom: 2.4rem !important;
    padding-inline: 2.4rem !important;

    @include respond(tab-port) {
      font-size: 1.75rem !important;
      line-height: 2.5rem !important;
    }
  }
}

.checkoutEmail {
  margin: 0 !important;
}

.checkoutEmail {
  .mantine-TextInput-description {
    color: $color-dusty-gray !important;
    font-weight: 500 !important;
    font-size: 1.2rem !important;
    line-height: 1.6rem !important;

    @include respond(tab-port) {
      font-size: 1.5rem !important;
      line-height: 1.9rem !important;
    }
  }
}

.mantine-Breadcrumbs-root {
  display: flex !important;
  flex-wrap: wrap !important;
}

.mantine-Select-input {
  padding: 1.1rem 1.2rem !important;
  font-family: 'Avenir' !important;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
  line-height: 2.1rem !important;
  color: $color-dark-grey-secondary !important;
}

.mantine-Select-dropdown .mantine-Select-item {
  font-family: 'Avenir' !important;
  font-weight: 500 !important;
  font-size: 1.3rem !important;
  line-height: 2.2rem !important;
  color: $color-dark-grey-secondary !important;
  border-radius: 0px !important;
}

.mantine-Select-dropdown .mantine-Select-item[data-selected='true'] {
  color: $color-white !important;
  background: $color-blue-primary !important;
}
