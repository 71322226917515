@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.topBar, .topBarFixed {
  position: relative;
  margin-bottom: 1.6rem;
  padding-top: 1.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-white;
  height: 115px;

  @include respond(tablet) {
    margin-bottom: 0;
    padding-top: 0;
    flex-direction: column-reverse;
    align-items: stretch;
    height: auto;
  }
}

.topBarFixed {
  padding-inline: 11%;
  padding-top: 2rem;
  padding-bottom: 2.4rem;
  position: fixed;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  top: -100%;
  left: 0;
  z-index: 10000;
  transition: all 0.5s ease-in-out;

  @include respond(big-desktop) {
    padding-inline: 13%;
  }

  @include respond(tab-land) {
    padding-inline: 9%;
  }
}

.onlyLogoFixed {
  @include respond(tab-port) {
    margin: 0 !important;
  }
}

.show {
  top: 0;
}

.dropdownContainer {
  position: absolute;
  top: 0;
  padding-top: 84px;
  right: 0;
  min-width: 23rem;
  z-index: 1000;
  @include respond(big-desktop) {
    padding-top: 100px;
  }
}

.moved {
  top: 8rem;
  right: 11%;
}
