@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.checkboxWrapper {
  padding-block: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.error {
  padding-top: 1rem;
  color: $color-orange;
  font-size: 1.2rem;

  @include respond(tab-port) {
    font-size: 1.5rem;
  }
}

.checkboxWrapper {
  padding: none;
}

.btn {
  padding: none;
}

.checkbox {
  padding: none;
}

.header {
  padding: none;
}

.openEyeIcon, .closedEyeIcon {
  color: $color-dark-white;
}

.avenirFont {
  font-family: 'Avenir';
}

.link {
  color: $color-dark-grey-secondary;
  text-decoration: underline;
}

.border {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid $color-light;
  line-height: 0.1rem;
  margin: 1.4rem 0;
}

.borderText {
  color: $color-dark-grey-secondary;
  background: $color-white;
  padding: 0 1.6rem;
  font-size: 1.4rem;
}
