@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.dropdown {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  background-color: $color-white;
  box-shadow: 0px 0px 5px rgba(37, 37, 37, 0.1);
  z-index: 1000;

  & a {
    padding: 1.6rem;
    display: block;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.9rem;
    text-decoration: none;
    width: 100%;
    height: 100%;
    color: $color-dark-grey-secondary;

    @include respond(tab-port) {
      font-size: 1.5rem;
      line-height: 2.2rem;
    }
  }

  @include respond(tab-port) {
    margin-top: 0rem;
  }
}

.item {
  border-bottom: 2px solid $color-light;

  &:last-child {
    border-bottom: none;
  }
}
