@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.link {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: $color-dark-grey-secondary;
  transition: all 0.3s ease;

  & svg {
    width: 2rem;
    height: 2rem;

    @include respond(tab-port) {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &:hover {
    color: $color-blue-primary;
    text-decoration: underline;
  }

  &:focus {
    color: $color-blue-primary;
    text-decoration: underline;
  }
}

.label {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.3rem;

  @include respond(tab-port) {
    font-size: 1.75rem;
    line-height: 1.6rem;
  }
}

.isCapitalize::first-letter {
  text-transform: uppercase;
}

.isUppercase {
  text-transform: uppercase;
}

.isCapitalize {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.3rem;
}
