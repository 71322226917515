@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.nav {
  padding-inline: 0;
  width: 100%;
  background-color: $color-light;
  transition: all 0.3s ease;
  z-index: 1000;
}

.shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.navWrapper {
  padding-block: 1.9rem;
  display: flex;
  background-color: $color-white;
  padding-inline: 11%;

  @include respond(big-desktop) {
    padding-inline: 13%;
  }

  @include respond(tab-land) {
    padding-inline: 9%;
  }

  @include respond(tablet) {
    margin-bottom: 0;
    padding-bottom: 3.2rem;
  }

  @include respond(tab-port) {
    padding-inline: 7.5%;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  @include respond(mobile) {
    padding-inline: 6%;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.logo {
  @include respond(big-desktop) {
    width: 8.5rem;
    height: 8.5rem;
  }

  @include respond(tab-land) {
    width: 7.5rem;
  }

  @include respond(tablet) {
    width: 6.3rem;
  }

  @include respond(tab-port) {
    height: fit-content;
    width: 5.7rem;
  }
  @include respond(mobile) {
    height: fit-content;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  gap: 4rem;

  @include respond(tab-port) {
    justify-content: space-between;
    align-items: end;
    gap: 1.5rem;
  }

  @include respond(mobile) {
    justify-content: space-between;
    align-items: end;
    gap: 1rem;
  }
}

.leftCol {
  transition: all 0.4s ease;
}

.hide {
  opacity: 0;
  height: 2rem;
  transform: translateY(-100%);
  pointer-events: none;
}

.backToCart {
  display: none;

  @include respond(tablet) {
    padding-inline: 7.5%;
  }

  @include respond(tab-port) {
    padding-block: 2.1rem;
    margin-top: 2rem;
    display: block;
    background-color: $color-white;
  }

  @include respond(mobile) {
    padding-inline: 1.6rem;
  }
}

.stepperContainer {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 100%;

  @include respond(tab-port) {
    width: fit-content;
  }
  @include respond(mobile) {
    width: fit-content;
    justify-content: space-between;
  }
}

.stepperItem {
  color: $color-dusty-gray;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.4rem;
  border-bottom: 3px solid $color-dusty-gray;
  padding-bottom: 1rem;
  min-width: 21.2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  @include respond(tab-port) {
    min-width: 20rem;
  }

  @include respond(mobile) {
    border-bottom: none;
    flex-direction: column;
    font-size: 1.75rem;
    line-height: 1.75rem;
    min-width: fit-content;
  }
}

.stepperActive {
  font-weight: 600;
  color: $color-blue-primary;
  border-bottom: 3px solid $color-blue-primary;

  @include respond(mobile) {
    border-bottom: none;
  }
}

.checkoutStepBox {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 5px;

  .checkoutStepBoxUp {
    color: var(--Light-grey, #CCC);
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: .3rem;

    strong {
      color: var(--Dark-grey_secondary, #333);
    }

    a {
      width: 19px;
      height: 19px;
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        position: absolute;
        top: calc(50% + 5px);
        left: 50%;
        width: 8px;
        height: 8px;
        border-left: 2px solid #CCC;
        border-bottom: 2px solid #CCC;
        transform: translate(-50%,-50%) rotate(45deg);
      }
    }
  }
  .checkoutStepBoxBottom {
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
}