@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.container {
  position: relative;
}

.expandedContainer.opened {
  display: flex;
}

.expandedContainer {
  z-index: 999;
  box-sizing: border-box;
  width: 30rem;
  position: absolute;
  top: 7rem;
  right: 0;
  background-color: $color-white;
  box-shadow: 0px 0px 10px rgba(37, 37, 37, 0.1);
  display: none;
  flex-direction: column;
  padding: 0 0.9rem 1.7rem 0.9rem;
}

.title {
  margin-top: 0;
}

.items {
  overflow-y: scroll;
  max-height: 300px;
  scrollbar-width: none; 

  &::-webkit-scrollbar-thumb {
    background-color:$color-dark-white !important;
    border-radius: 8px;
  }

  &::-webkit-scrollbar {
    background-color: $color-white !important;
    width: 3px;
  }
}

.goToCart {
  @include respond(tab-port) {
    font-size: 1.6rem !important;
    line-height: 1.3rem !important;
  }
}

.total,
.title {
  font-weight: 600;
  font-size: 2rem;
}

.closeBtn {
  width: 2.8rem;
  height: 2.8rem;
  background: $color-light !important;

  & img {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.2rem;
  margin-top: 1.6rem;
  padding-inline: 0.4rem;

  @include respond(tab-port) {
    margin-bottom: 2rem;
    margin-top: 1.6rem;
  }
}

.header {
  padding: 1.7rem 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color-lightgrey;
}

.buttonContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.itemCounter {
  padding: 0.3rem 0.4rem;
  position: absolute;
  background-color: $color-blue-primary;
  width: 1.65rem;
  height: 1.65rem;
  font-size: 1.25rem;
  border-radius: 50%;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0.3rem;
  top: -0.2rem;

  @include respond(big-desktop) {
    top: 0.1rem;
    width: 1.75rem;
    height: 1.75rem;
    font-size: 1.4rem;
  }

  @include respond(tab-land) {
    top: -0.6rem;
    right: -0.4rem;
    width: 2.2rem;
    height: 2.2rem;
    font-size: 12.5px;
  }

  @include respond(tab-port) {
    top: -0.75rem;
    right: -0.4rem;
    width: 2.7rem;
    height: 2.7rem;
    font-size: 12px;
  }
}

.emptyCartInfo {
  margin: 2rem 0;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}
