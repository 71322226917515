@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.iconButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  cursor: pointer;
  border: none;
  transition: opacity 150ms;
  height: 44px;
  font-family: inherit;
  font-size: 1.4rem;
  @include respond(big-desktop) {
    height: 60px;
  }
  & > img {
    width: 20px;
    @include respond(big-desktop) {
      width: 31px;
      height: 31px;
    }
  }
}

.iconButton:hover {
  opacity: 75%;
}

.iconButton:active {
  opacity: 50%;
}

.iconButton__link {
  text-decoration: none;
}
