@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  padding-block: 9.6rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  color: $color-dark-white;
  background-color: $color-dark-grey;
  gap: 6.4rem;

  & a {
    color: $color-dark-white;
    text-decoration: none;
  }

  @include respond(tab-land) {
    padding-block: 8.6rem;
  }

  @include respond(tab-port) {
    padding-block: 5.6rem;
    gap: 4rem;
  }

  @include respond(mobile) {
    padding-top: 3rem;
    padding-bottom: 6rem;
  }
}

.hidden {
  z-index: -1;
}

.top {
  display: flex;
  justify-content: space-between;
}

.logoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  gap: 2.3rem;
  min-width: fit-content;

  & img {
    @include respond(tab-land) {
      width: 8.5rem;
      height: 8.5rem;
    }

    @include respond(tab-port) {
      width: 6.8rem;
      height: 6.8rem;
    }
  }

  @include respond(tab-land) {
    gap: 1.8rem;
  }

  @include respond(tab-port) {
    margin-bottom: 3.2rem;
    width: 100%;
    flex-direction: row;
    font-size: 2rem;
    line-height: 3rem;
  }
}

.footerNav {
  width: 100%;
  display: flex;
  column-gap: 4rem;

  @include respond(tab-port) {
    flex-direction: column;
  }
}

.colWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2rem;

  @include respond(tab-port) {
    flex-wrap: wrap;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0rem;
  }
}

.col1,
.col4 {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.col1,
.col2,
.col3,
.col4 {
  @include respond(tab-land) {
    padding-right: 1rem;
  }

  @include respond(tab-port) {
    flex: 0 47%;
  }
}

.col1,
.col2 {
  @include respond(tab-port) {
    margin-bottom: 3.2rem;
  }
}

.label {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.4rem;

  @include respond(tab-port) {
    font-size: 1.9rem;
  }
}

.avenirFont {
  font-weight: 800;
}

.socials {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.socialsWrapper {
  padding-left: 0.8rem;
  display: flex;
  gap: 2.2rem;

  & img {
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  @include respond(tab-port) {
    padding-left: 0.6rem;
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.bottomRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.description {
  padding-right: 7rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;

  @include respond(big-desktop) {
    padding-right: 15rem;
  }

  @include respond(tab-port) {
    padding-right: 2rem;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

.copyrights {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;

  display: flex;
  gap: 5px;
  align-items: center;

  @include respond(mobile) {
    flex-direction: column;
    gap: 0;
    align-items: start;
    div {
      display: none;
    }
  }

  @include respond(tab-port) {
    font-size: 1.5rem;
    line-height: 3rem;
  }

  a {
    text-decoration: underline;
  }
}

.paymentMethods {
  display: flex;
  align-items: center;
  gap: 2.4rem;

  & img {
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    @include respond(tab-land) {
      height: 2.2rem;

      &:first-child {
        height: 1.7rem;
      }
    }

    @include respond(tab-port) {
      height: 1.9rem;

      &:first-child {
        height: 1.5rem;
      }
    }
  }

  @include respond(tab-land) {
    gap: 2rem;
  }

  @include respond(tab-land) {
    gap: 0.4rem;
  }
}

.newsletterInputWrapper {
  position: relative;
  margin-top: 1rem;
  display: flex;
  align-items: center;

  input {
    padding: 0.4rem 0.8rem !important;
    height: unset !important;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2.4rem;

    &::placeholder {
      color: $color-dark-grey;
    }

    @include respond(tab-port) {
      padding: 0.75rem 1rem !important;
      font-size: 1.5rem;
    }
  }
}

.newsletterButton {
  position: absolute;
  right: 0.4rem;
  background-color: $color-dark-grey;
  color: $color-dark-white;
  border: none;
  padding: 0.25rem 0.9rem !important;
  line-height: 1.3em !important;
  text-transform: none !important;
  transition: 0.2s background linear;

  &:disabled {
    background-color: $color-dove-gray !important;
  }

  @include respond(tab-port) {
    padding: 0.4rem 2.5rem !important;
    right: 0.3rem;
    font-weight: 500;
    font-size: 1.6rem !important;
    line-height: 2.15rem !important;
  }
}

.text {
  margin-top: 1rem;
  font-size: 1.4rem;
  line-height: 1.8rem;

  @include respond(tab-port) {
    margin-block: 1.3rem;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }

  @include respond(mobile) {
    width: 100%;
  }
}

.newsletter {
  grid-column: 4/5;
  position: relative;

  @include respond(small-desktop) {
    grid-column: 3/5;
  }

  @include respond(tab-port) {
    margin-top: 3rem;
    grid-column: 1;
  }

  @include respond(mobile) {
    margin-top: 4rem;
    grid-column: 1/3;
  }
}

.error {
  color: $color-orange;
  font-size: 1.2rem;
  position: absolute;
  left: 0;
  bottom: -2.8rem;

  @include respond(tab-port) {
    font-size: 1.5rem;
  }
}

.success {
  color: $color-sea-green;
  margin-top: 1rem;
  font-size: 1.2rem;
}
