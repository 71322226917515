@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.input {
  padding: 1.1rem 1.2rem;
  width: 100%;
  height: 3.8rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  border: 1px solid $color-dark-white;
  border-radius: 0 !important;

  @include respond(tab-land) {
    height: 4.4rem !important;
  }

  @include respond(tab-port) {
    height: 5rem !important;
    font-size: 1.6rem;
  }

  @include respond(mobile) {
    font-size: 1.75rem;
    line-height: 2.6rem;
  }
}

.darkBgc {
  background-color: $color-lightgrey;
}

.borderNone {
  border: 1px solid $color-lightgrey !important;
}

.error {
  color: $color-orange;
  font-size: 1.2rem;

  @include respond(tab-port) {
    font-size: 1.5rem;
  }
}
