@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.spinner {
  padding: 0 !important;
  justify-content: center;
}

.withPadding {
  padding: 6rem 0 !important;
}

:export {
  primaryColor: $color-blue-primary;
  primaryColorLight: $color-blue-primary;
}
