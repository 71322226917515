@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.container {
  margin-left: 1.25rem;
  position: relative;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.openedMenu {
  background-color: $color-light;
}

.paddings {
  padding-inline: 9%;

  @include respond(tab-port) {
    padding-inline: 7.5%;
  }

  @include respond(mobile) {
    padding-inline: 6%;
  }
}

.menuWrapper {
  position: absolute;
  top: 15rem;
  left: -100vw;
  background-color: $color-white;
  height: 100vh; /* old browsers */  
  height: 100dvh; /* new browsers */  
  width: 100vw;
  z-index: 100;
  overflow: auto;

  &.opened {
    left: 0px;
    transition: all 0.3s ease-in-out;
    transition-delay: 0.2s;
  }

  @include respond(mobile) {
    top: 18rem;
    padding-bottom: 25rem;
  }
}

.vendorButton {
  margin-top: 4rem;
}

.links {
  margin-top: 3rem;
}

.links, .contact {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.linkItem {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5rem;
  text-decoration: none;
  color: $color-dark-grey-secondary;
  transition: all 0.3s ease;

  &:hover {
    color: $color-black;
    text-decoration: underline;
  }
}
