@import '../abstracts/variables';
@import '../abstracts/mixins';

.mantine-Dialog-root {
  padding: 2rem !important;
  font-size: 1.4rem !important;
  line-height: 2rem !important;
  color: $color-white !important;
  background-color: $color-blue-primary !important;

  & button {
    top: 0.5rem !important;
    right: 0.5rem !important;
    width: 2.5rem !important;
    height: 2.5rem !important;

    & svg {
      width: 2rem !important;
      height: 2rem !important;
    }

    &:hover {
      opacity: 0.8;
      background-color: $color-blue-primary !important;
    }
  }
}
