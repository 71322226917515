@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  padding-block: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  color: $color-dark-white;
  background-color: $color-dark-grey;

  & a {
    color: $color-dark-white;
    text-decoration: none;
  }

  @include respond(tab-land) {
    padding-block: 4rem;
  }

  @include respond(tablet) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4rem;
  }
}

.logoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.4rem;

  & p {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.4rem;

    @include respond(tablet) {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  @include respond(tablet) {
    flex-direction: row;
    gap: 2rem;
  }
}

.center {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.paragraphBox {
  display: flex;
  align-items: center;
  gap: 1rem;

  & p {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.2rem;

    @include respond(tablet) {
      font-size: 1.75rem;
      line-height: 2.8rem;
    }
  }
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.6rem;

  @include respond(tablet) {
    align-items: flex-start;
    gap: 2rem;
  }
}

.topRow {
  display: flex;
  align-items: center;
  gap: 1.6rem;

  & a {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-decoration: underline;

    @include respond(tablet) {
      font-size: 1.75rem;
      line-height: 3rem;
    }
  }
}

.paymentMethods {
  display: flex;
  align-items: center;
  gap: 2rem;
}
