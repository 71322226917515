@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.marketingTopbarBg {
    background: $color-blue-primary;
    color: $color-white;
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
}

.marketingTopbarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 11%;
    position: relative;
    height: 40px;

    @include respond(big-desktop) {
        padding-inline: 13%;
    }

    @include respond(tab-land) {
        padding-inline: 9%;
    }

    @include respond(tab-port) {
        padding-inline: 7.5%;
    }

    @include respond(mobile) {
        padding-inline: 6%;
    }
}

.marketingText {
    font-size: $font-size-2xl;
    margin-right: 1rem;
}

.marketingButton {
    color: $color-white;
    font-size: $font-size-2xl;
}

.closeBtn {
    position: absolute;
    right: 11%;
    width: 20px;
    height: 20px;
    @include respond(big-desktop) {
        right: 13%;
    }

    @include respond(tab-land) {
        right: 9%;
    }

    @include respond(tab-port) {
        right: 7.5%;
    }

    @include respond(mobile) {
        right: 6%;
    }
    svg {
        fill: #fff;
    }
}
