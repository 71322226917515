@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  display: grid;
  // TODO: Delete & uncomment after fix Facebook login
  grid-template-columns: 1fr;
  // grid-template-columns: 1fr 1fr;
  gap: 0.8rem;
}

.button {
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1.3rem 4.35rem;
  color: $color-dark-grey-secondary;
  background-color: $color-light;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.92;
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    background-color: $color-lightgrey;
  }

  @include respond(tab-port) {
    padding: 1.6rem 2.8rem;
  }
}
