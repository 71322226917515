@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.socialHeaderBg {
    background: #252525;
    color: #fff;
}

.socialHeaderContainer {
    display: flex;
    justify-content: space-between;

    padding-block: 10px;
    padding-inline: 11%;

    @include respond(big-desktop) {
        padding-inline: 13%;
    }

    @include respond(tab-land) {
        padding-inline: 9%;
    }

    @include respond(tab-port) {
        padding-inline: 7.5%;
    }

    @include respond(mobile) {
        padding-inline: 6%;
    }
}

.socialLeftBox {
    display: flex;
    gap: 10px;
}

.socialRightBox {
    a {
        display: flex;
        gap: 8px;
        
        color: var(--White, #FFF);
        font-size: 13px;
        font-weight: 500;
        line-height: 120%;

        text-decoration: none;
    }
}