@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.smallerLogo {
  width: 6rem;
  height: 6rem;
}

.centered {
  align-items: center;
}

.left,
.right,
.rightCol {
  display: flex;
}

.left {
  align-items: flex-start;
  gap: 1.6rem;
}

.right {
  flex: 0 52%;
  align-items: center;
  justify-content: space-between;

  @include respond(tablet) {
    flex: 1;
    align-items: center;
  }
}

.vendorButton {
  @include respond(tablet) {
    display: none;
  }
}

.logo {
  @include respond(big-desktop) {
    width: 8.4rem;
    height: 8.4rem;
  }

  @include respond(tab-land) {
    width: 7.4rem;
  }

  @include respond(tablet) {
    width: 6.3rem;
  }

  @include respond(tab-port) {
    width: 5.7rem;
  }
}

.searchDesktop {
  position: relative;
  width: 26rem;
  height: 3.6rem;

  & input {
    @include respond(tab-land) {
      height: 3.8rem !important;
    }
  }

  @include respond(tablet) {
    display: none;
  }
}

.rightCol {
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
}

.foreign {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.3rem;

  & a {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: $color-dark-grey-secondary;
    text-decoration: none;
  }

  & img {
    @include respond(big-desktop) {
      width: 1.7rem;
      height: 1.7rem;
    }
  }

  @include respond(big-desktop) {
    font-size: 1.5rem;
  }

  @include respond(tablet) {
    display: none;
  }
}

.iconsNav {
  display: flex;
  align-items: center;
  gap: 3.2rem;
  color: $color-dark-grey-secondary;

  & button {
    & p {
      font-weight: 500;
      color: $color-dark-grey-secondary;

      @include respond(tab-port) {
        font-size: 1.6rem;
      }
    }
  }
}

.onlyLogo {
  margin-top: 3.2rem !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  justify-content: center !important;
  align-items: center !important;

  @include respond(mini-desktop) {
    margin-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
