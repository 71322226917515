@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include respond(tab-port) {
    gap: 10px;
  }
}

.label {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.4rem;

  @include respond(tab-port) {
    font-size: 1.9rem;
    line-height: 3rem;
  }
}

.avenirFont {
  font-weight: 800;
}

.link {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;

  &:hover {
    text-decoration: underline;
  }

  @include respond(tab-port) {
    font-size: 1.75rem;
    line-height: 3rem;
  }
}
