.slider, .slick-slider {
  padding-inline: 4rem;
  display: flex;
  max-width: 800px;
  overflow: auto;

  &::-webkit-scrollbar-thumb {
    background-color:$color-dark-white !important;
    border-radius: 6px;
  }

  &::-webkit-scrollbar {
    background-color: $color-white !important;
    height: 6px;
  }
  
  @include respond(tab-port) {
    max-width: 500px;
  }

  @include respond(mobile) {
    max-width: 400px;
  }
}
