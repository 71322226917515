*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  overflow-x: hidden;

  @include respond(tab-land) {
    // width < 1200?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) {
    // width < 900?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12, 12/16
  }
}

@font-face {
  font-family: 'Avenir';
  src: url(/src/fonts/avenir.ttc) format('ttc');
  font-display: optional;
  font-weight: 400;
}

body {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

section,
nav,
footer {
  padding-inline: 11%;

  @include respond(big-desktop) {
    padding-inline: 13%;
  }

  @include respond(tab-land) {
    padding-inline: 9%;
  }

  @include respond(tab-port) {
    padding-inline: 7.5%;
  }

  @include respond(mobile) {
    padding-inline: 6%;
  }
}

input, textarea {
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  zoom: 1;
}

// Remove type number input arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.gradientColorSwatch {
  .mantine-ColorSwatch-alphaOverlay {
    background-image: none;
  }
}
