// COLORS
$color-black: #000;
$color-white: #fff;
$color-blue-primary: #00008D;
$color-blue-light: #EEF4FC;
$color-dark-grey-secondary: #333333;
$color-dark-grey: #252525;
$color-dark-white: #CCCCCC;
$color-lightgrey: #E1E2E5;
$color-light: #F6F7F8;
$color-orange: #EC420D;
$color-sea-green: #319035;
$color-dove-gray: #707070;
$color-dusty-gray: #949494;

// FONT
$default-font-size: 1.6rem;
$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-md: 1rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.25rem;
$font-size-2xl: 1.5rem;
$font-size-3xl: 1.875rem;
$font-size-4xl: 2.25rem;

//SCREEN-WIDTH
$tablet: 'max-width: 999px';
$screen-width-xs: 320px;
$screen-width-sm: 640px;
$screen-width-md: 768px;
$screen-width-lg: 1024px;
$screen-width-xl: 1280px;
$screen-width-2xl: 1536px;
