@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  padding-block: 6.4rem;
  color: $color-dark-grey-secondary;
  background-color: $color-light;
  height: 100vh;
  padding-top: 10%;

  @include respond(tab-port) {
    padding-block: 4rem;
  }
}

.logo {
  @include respond(big-desktop) {
    width: 8.4rem;
    height: 8.4rem;
  }

  @include respond(tab-land) {
    width: 7.4rem;
  }

  @include respond(tablet) {
    width: 6.3rem;
  }

  @include respond(tab-port) {
    width: 5.7rem;
  }
}

.wrapper {
  padding-inline: 5rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;

  @include respond(tab-port) {
    padding-inline: 1.2rem;
    flex-direction: column;
    gap: 4rem;
  }
}

.textWrapper {
  display: flex;
  flex: 1 0 45%;

  @include respond(tab-land) {
    flex: 1 0 50%;
  }

  @include respond(tab-port) {
    max-width: 330px;
    flex: 100%;
  }
}

.imageWrapper, .imageMobileWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 55%;

  @include respond(tab-land) {
    flex: 1 0 50%;
  }

  @include respond(tab-port) {
    flex: 100%;
  }
}

.imageWrapper {
  & img {
    width: 100%;
    height: auto;

    @include respond(tab-port) {
      width: auto;
      height: auto;
    }
  }

  @include respond(tab-port) {
    display: none;
  }
}

.imageMobileWrapper {
  display: none;

  @include respond(tab-port) {
    display: flex;
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 2.6rem;
}

.headline {
  font-weight: 800;
  font-size: 2.6rem;
  line-height: 3.6rem;

  @include respond(tab-port) {
    font-size: 2.8rem;
    line-height: 4rem;
  }
}

.links {
  margin-top: 1.6rem;
  display: flex;
  gap: 16px;

  @include respond(tab-port) {
    gap: 11px;
  }
}

.btn {
  padding: 1.4rem 1.8rem;
  background-color: $color-light !important;

  @include respond(tablet) {
    padding: 1rem 1.6rem !important;
    font-size: 1.65rem;
  }
}

.text, .cta {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.5rem;

  & a {
    color: $color-blue-primary;
    text-decoration: underline;

    &:hover {
      color: $color-dark-grey-secondary;
    }
  }

  @include respond(tab-port) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.recommendedProductsWrapper {
  margin-bottom: 6rem;

  @include respond(tab-port) {
    margin-block: 5rem;
  }
}
