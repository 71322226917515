@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.content {
  padding-block: 3.4rem;
  padding-inline: 4.3rem !important;

  @include respond(tab-port) {
    padding-block: 4.25rem;
    padding-inline: 3.8rem !important;
  }
}

.closeButton {
  width: 2.5rem !important;
  height: 2.5rem !important;
  background: $color-light !important;

  & svg {
    width: 2rem !important;
    height: 2rem !important;
    color: $color-dark-grey-secondary !important;

    @include respond(tab-port) {
      width: 3rem !important;
      height: 3rem !important;
    }
  }

  @include respond(tab-port) {
    width: 3.8rem !important;
    height: 3.8rem !important;
  }
}

.header {
  padding: 0;
  display: flex;
  align-items: center;
}

.title {
  font-weight: 600 !important;
  font-size: 1.8rem !important;
  line-height: 2.5rem !important;
  text-transform: uppercase;
  color: $color-dark-grey-secondary !important;

  @include respond(tab-port) {
    font-size: 2.25rem !important;
    line-height: 3.1rem !important;
  }
}

.registration, .vendorLogging {
  padding-block: 3.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @include respond(tab-port) {
    gap: 2rem;
  }
}

.registration {
  margin-top: 1.6rem;
  border-block: 2px solid $color-light;

  @include respond(tab-port) {
    margin-top: 2rem;
  }
}

.label {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.5rem;
  text-transform: uppercase;

  @include respond(tab-port) {
    font-size: 2.25rem;
    line-height: 3.1rem;
  }
}

.avenirFont {
  font-weight: 900;
}

.logging {
  display: flex;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;

  & a {
    color: $color-dark-grey-secondary;
  }

  @include respond(tab-port) {
    font-size: 1.75rem;
    line-height: 2rem;
  }
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
  gap: 0 !important;
}
