@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.nav {
  margin-bottom: 3.2rem;
  width: 100%;
  background-color: $color-white;

  @include respond(tablet) {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}

.onlyLogoFixed {
  @include respond(tab-port) {
    margin: 0 !important;
  }
}

.searchMobile {
  position: relative;
  display: none;

  & input {
    @include respond(mobile) {
      min-height: 5rem;
    }
  }

  @include respond(tablet) {
    display: block;
    min-width: 30rem;
  }
}

.navigation {
  border-bottom: 2px solid $color-light;

  @include respond(tablet) {
    padding-block: 0;
    padding-bottom: 2.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
  }
}
