#mainImage {
  max-height: 600px !important;
  height: 100% !important;
  object-fit: cover;
}

.productImage {
  width: 100%;
  height: 100%;
}
