@import '../abstracts/variables';
@import '../abstracts/mixins';

.addToCartModal {
  & .container {
    color: $color-dark-grey-secondary;
    z-index: 1000;
  }

  & .mantine-Modal-inner {
    z-index: 1001;
  }

  & .modalHeader {
    border-bottom: 1px solid $color-lightgrey;
  }

  & .modalTitle {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.7rem;
  }

  & .modalWrapper {
    color: $color-dark-grey-secondary;
    padding: 0 3.2rem 3.2rem 3.2rem;
  }

  & .mantine-Modal-header {
    height: auto !important;
    padding: 3.3rem 0 !important;
    size: 2rem;
  }

  & .modalBody {
    padding: 3.2rem 1.6rem !important;

    @include respond(tab-port) {
      padding: 3.2rem 1.6rem !important;
    }
  }

  & .upperCloseButton {
    width: 2.8rem !important;
    height: 2.8rem !important;
    background: $color-light !important;

    & svg {
      width: 2.5rem !important;
      height: 2.5rem !important;
      color: $color-dark-grey-secondary !important;
    }
  }

  & .contentWrapper {
    display: flex;
    flex-wrap: nowrap;
    gap: 3.2rem;

    @include respond(tab-port) {
      gap: 2.5rem;
    }
  }

  & .metaInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & .productName {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  & .productPrice {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.7rem;
  }

  & .modalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3.2rem;
    border-top: 1px solid $color-lightgrey;

    @include respond(tab-port) {
      flex-direction: column;
      align-items: center;
    }
  }

  & .lowerCloseButton {
    color: $color-dark-grey-secondary;
    padding: 0;
    background-color: inherit;
    border: none;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.3rem;
    text-transform: uppercase;
    transition: all 0.3s ease;

    &:hover {
      color: $color-blue-primary;
    }

    &:focus {
      color: $color-blue-primary;
    }

    @include respond(tab-port) {
      margin-bottom: 1.6rem;
    }
  }

  & .avenirFont {
    font-family: 'Avenir';
  }

  & .cartButton {
    background-color: #04018D;
    border: 1.5px solid #04018D;
    color: $color-white;
    transition: all 0.3s ease;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.3rem;
    text-transform: uppercase;
    height: auto !important;
    margin-right: 1.6rem;

    &:hover {
      border: 1.5px solid #4a54ab;
      background-color: #4a54ab;
    }

    &:focus {
      border: 1.5px solid #4a54ab;
      background-color: #4a54ab;
    }

    & span {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }

    @include respond(tab-port) {
      width: 100%;
    }
  }
}

.deleteAddress {
  & .container {
    color: $color-dark-grey-secondary;
    z-index: 1000;
  }

  & .mantine-Modal-inner {
    z-index: 1001;
  }

  & .modalWrapper {
    display: flex;
    flex-direction: column;
    padding: 2.4rem;
    min-width: fit-content;
    gap: 1rem;

    @include respond(tab-port) {
      padding: 2rem;
    }
  }

  & .modalButton {
    color: $color-dark-grey-secondary !important;
    height: 3rem;
    width: 3rem;
    padding: 0.2rem;
    border: 1.5px solid $color-dark-grey-secondary;
    border-radius: 50%;
  }

  & .text {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.8rem;
    padding-top: 1rem;

    @include respond(tab-port) {
      font-size: 1.9rem;
      line-height: 2.2rem;
      padding-top: 1.25rem;
    }
  }

  & .modalFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 2.4rem;
  }

  & .buttonWrapper {
    width: fit-content;
  }

  & .modalCloseButton {
    text-transform: uppercase;
    width: fit-content;
    height: auto;
    padding: 1.2rem 2.2rem;
    font-family: 'Avenir';
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
    border: 2px solid $color-dark-grey-secondary;
    background-color: inherit;
    color: $color-dark-grey-secondary;

    &:hover {
      background-color: $color-lightgrey;
      border: 2px solid $color-blue-primary;
    }

    @include respond(mobile) {
      padding: 1.6rem 2rem;
      font-size: 1.875rem;
      line-height: 2.25rem;
      gap: 1rem;
    }
  }

  & .modalDeleteButton {
    text-transform: uppercase;
    width: fit-content;
    height: auto;
    padding: 1.2rem 2.2rem;
    font-family: 'Avenir';
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
    border: 2px solid $color-dark-grey-secondary;
    background-color: $color-dark-grey-secondary;
    color: $color-white;

    &:hover {
      background-color: $color-blue-primary;
      border: 2px solid $color-blue-primary;
    }

    @include respond(mobile) {
      padding: 1.6rem 2rem;
      font-size: 1.875rem;
      line-height: 2.25rem;
      gap: 1rem;
    }
  }
}
