@import '../abstracts/variables';
@import '../abstracts/mixins';

.mantine-Pagination-control {
  border: none !important;
  height: 3.6rem !important;
  min-width: 3.6rem !important;
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1.6rem !important;
  line-height: 2.2rem !important;

  & svg {
    width: 2rem !important;
    height: 2rem !important;
    color: $color-dark-grey-secondary !important;

    @include respond(tab-port) {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }
  }

  @include respond(tab-port) {
    height: 4.5rem !important;
    min-width: 4.5rem !important;
    font-size: 2rem !important;
    line-height: 2.8rem !important;
  }
}

.mantine-Pagination-control[data-active=true] {
  font-weight: 700;
  color: $color-dark-grey-secondary !important;
  background-color: $color-white !important;
  text-decoration: underline;
}

.mantine-Pagination-control[data-active=true]:not([data-disabled]):hover {
  color: $color-dark-grey-secondary !important;
  background-color: $color-white !important;
}

.mantine-Pagination-control:not([data-disabled]):hover {
  background-color: $color-white !important;
  text-decoration: underline !important;
}

.mantine-Pagination-control:focus {
  outline: none !important;
}

.mantine-Pagination-control:active {
  -webkit-transform: translateY(0) !important;
  transform: translateY(0) !important;
}

.mantine-Pagination-dots {
  display: flex;
  align-items: flex-end;

  & svg {
    height: 1.2rem;
    width: 1.2rem;
  }
}

.firstPaginationArrow, .prevPaginationArrow, .nextPaginationArrow, .lastPaginationArrow {
  background-color: $color-light !important;
}
