@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  display: flex;
  justify-content: space-between;

  @include respond(tablet) {
    flex-direction: column;
  }
}

.navTreeWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include respond(tablet) {
    &:last-child {
      border-bottom: 1px solid $color-lightgrey;
    }
  }
}

.orange {
  color: $color-orange !important;
}

.navSubItemsWrapper {
  margin-left: 1rem;
}

.navItem {
  padding-block: 3.2rem;
  position: relative;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.3rem;
  color: $color-dark-grey-secondary;
  text-decoration: none;
  cursor: pointer;

  @include respond(tab-land) {
    font-size: 1.6rem;
  }

  @include respond(tablet) {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 2rem 9%;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.3rem;
    border-top: 1px solid $color-lightgrey;
  }

  @include respond(tab-port) {
    padding: 2rem 7.5%;
    font-size: 2rem;
  }

  @include respond(mobile) {
    padding: 2rem 6%;
  }

  &:hover {
    color: $color-blue-primary;
  }
}

.navSubItemsWrapper {
  margin-top: 0.32rem !important;
  padding-top: 3.2rem;
  padding-bottom: 6.4rem;
  margin: 0;
  position: absolute;
  left: 0;
  width: 100vw;
  min-height: 468px;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  background-color: $color-light;
  z-index: 999;
  box-shadow: 0px 6px 10px inset rgba(37, 37, 37, 0.1);
}

.navSubItems {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: 1 1;
}

.subMenuItems {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
  column-gap: 6.4rem;
  max-height: 250px;

  @include respond(tab-land) {
    max-height: 230px;
  }

  @include respond(tablet) {
    max-height: 100%;
    gap: 0;
  }
}

.categoryImage {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;

  & img {
    height: auto;
    width: 100%;
    object-fit: cover;
  }
}

.linkWrapper {
  position: absolute;
  bottom: 0;

  & a {
    width: max-content;
  }

  @include respond(tablet) {
    position: inherit;

    & p {
      font-weight: 600;
      font-size: 1.75rem;
      line-height: 1.75rem;
    }
  }
}

.activeItem {
  color: $color-blue-primary !important;
}

.activeBorder {
  border-bottom: 3px solid $color-blue-primary !important;

  @include respond(tablet) {
    border: none !important;
  }
}

.navSubItem {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.3rem;
  width: fit-content;
  color: $color-dark-grey-secondary;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: $color-blue-primary;
    text-decoration: underline;
  }

  &:first-child {
    border-top: none;
  }

  @include respond(tablet) {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 2rem 9%;
    font-weight: 400;
    font-size: 1.75rem;
    line-height: 1.6rem;
    width: 100%;
    border-top: 1px solid $color-lightgrey;
  }

  @include respond(tab-port) {
    padding: 2rem 7.5%;
  }

  @include respond(mobile) {
    padding: 2rem 6%;
  }
}

.overlay {
  width: 100vw;
  height: 50vh;
  position: fixed;
  background: $color-black;
  bottom: 0;
  left: 0;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.77, 0.26, 0, 0.98);

  &.visible {
    visibility: visible;
    opacity: 0.5;
  }
}

.chevronIcon {
  width: 2rem;
  height: 2rem;
}

.openCategoryWrapper {
  padding: 2rem 6% 2rem 4.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-dark-grey-secondary;
  background-color: $color-light;
  gap: 1rem;
}

.openCategory {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2rem;
  cursor: pointer;
}

.hidden {
  display: none;
}
