@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.container {
  border-bottom: 1px solid $color-lightgrey;
  display: flex;
  padding: 1.6rem 0.9rem;
  display: flex;

  & img {
    object-fit: cover;
  }
}

.title {
  margin-bottom: 0.8rem;
  max-width: 10rem;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: $color-dark-grey-secondary;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @include respond(tab-port) {
    font-size: 1.5rem;
  }
}

.trashIcon {
  margin-left: auto;
  cursor: pointer;
}

.productInfo {
  margin-left: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.pricing {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.9rem;

  @include respond(tab-port) {
    font-size: 1.5rem;
  }
}
