@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.btn {
  padding: 1.1rem 1.3rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.3rem;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }

  @include respond(tablet) {
    padding: 1.6rem;
    font-size: 1.8rem;
    line-height: 3rem;
  }

  @include respond(tab-port) {
    padding: 1.6rem;
    font-size: 2rem;
    line-height: 3rem;
  }

  @include respond(mobile) {
    padding: 1.6rem 2rem;
  }
}

.primary {
  background-color: $color-blue-primary;
  border: 1.5px solid $color-blue-primary;
  color: $color-white;
  transition: all 0.3s ease;

  &:hover {
    border: 1.5px solid $color-dark-grey-secondary;
    background-color: $color-dark-grey-secondary;
  }

  &:focus {
    border: 1.5px solid $color-dark-grey-secondary;
    background-color: $color-dark-grey-secondary;
  }
}

.secondary {
  background-color: $color-dark-grey-secondary;
  border: 1.5px solid $color-dark-grey-secondary;
  color: $color-white;
  transition: all 0.3s ease;

  &:hover {
    border: 1.5px solid $color-blue-primary;
    background-color: $color-blue-primary;
  }

  &:focus {
    border: 1.5px solid $color-blue-primary;
    background-color: $color-blue-primary;
  }
}

.checkoutButton {
  background-color: #04018D;
  border: 1.5px solid #04018D;
  color: $color-white;
  transition: all 0.3s ease;

  &:hover {
    border: 1.5px solid #4a54ab;
    background-color: #4a54ab;
  }

  &:focus {
    border: 1.5px solid #4a54ab;
    background-color: #4a54ab;
  }
}

.white {
  padding: 1.8rem 2.45rem;
  background-color: $color-white;
  color: $color-blue-primary;
  border: 1.5px solid $color-white;
  transition: all 0.3s ease;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
  }
}

.outlinePrimary {
  background-color: $color-white !important;
  color: $color-blue-primary;
  transition: all 0.3s ease;

  &:hover {
    color: $color-white;
    background-color: $color-blue-primary !important;
  }

  &:focus {
    color: $color-white;
    background-color: $color-blue-primary !important;
  }
}

.outlineSecondary {
  background-color: $color-white !important;
  color: $color-dark-grey-secondary;
  border: 1.5px solid $color-dark-grey-secondary;
  transition: all 0.3s ease;

  &:hover {
    color: $color-white;
    background-color: $color-dark-grey-secondary !important;
    border: 1.5px solid $color-dark-grey-secondary;
  }

  &:focus {
    color: $color-white;
    background-color: $color-dark-grey-secondary !important;
    border: 1.5px solid $color-dark-grey-secondary;
  }
}

.capitalize {
  text-transform: initial;
}

.fullWidth {
  width: 100%;
}

.active {
  background-color: $color-blue-primary !important;
  border: 1px solid $color-blue-primary !important;
  color: $color-white !important;
  transition: all 0.3s ease;

  &:hover {
    background-color: $color-blue-primary !important;
  }
}

.activeOutline {
  color: $color-white !important;
  background-color: $color-dark-grey-secondary !important;
  border: 1.5px solid $color-dark-grey-secondary !important;

    &:hover {
      background-color: $color-dark-grey-secondary !important;
    }
}

.disabled {
  background-color: $color-dark-white !important;
  border: 1px solid $color-dark-white !important;
  pointer-events: none;
}

.disabledIcon {
  background: none !important;
  border: none !important;
  pointer-events: none;

  & svg {
    color: $color-dark-white !important;
  }
}

.rounded {
  border-radius: 50px;
}

.password,
.iconType {
  padding: 0;
  position: absolute;
  right: 1.1rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  color: $color-dark-grey-secondary;
  background: none;
  border: none;
  cursor: pointer;

  & svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  &:hover {
    background: none;
    border: none;
  }
}

.withIconButton {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: $color-dark-grey-secondary;
  background: none;
  border: none;
  cursor: pointer;

  @include respond(tab-port) {
    font-size: 2.25rem;
    line-height: 2.8rem;
  }
}

.iconButton {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1rem;
  color: $color-dark-grey-secondary;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    background: none;
  }

  &:focus {
    background: none;
  }
  
  & img {
    @include respond(big-desktop) {
      width: 2.6rem;
      height: 2.6rem;
    }

    @include respond(tablet) {
      width: 2.4rem;
      height: 2.4rem;
    }

    @include respond(tab-port) {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  @include respond(big-desktop) {
    font-size: 1.5rem;
  }
}

.iconLink {
  text-decoration: none;
}

.heroButton {
  padding: 1.8rem 3.7rem;
  background-color: $color-dark-grey-secondary;
  border: 1.5px solid $color-dark-grey-secondary;
  color: $color-white;
  transition: all 0.3s ease;

  &:hover {
    border: 1.5px solid $color-blue-primary;
    background-color: $color-blue-primary;
  }

  &:focus {
    border: 1.5px solid $color-blue-primary;
    background-color: $color-blue-primary;
  }

  @include respond(tab-port) {
    padding: 1.9rem 1.25rem !important;
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
}

.biggerPadding {
  padding-block: 1.6rem;
  
  @include respond(tablet) {
    padding-block: 1rem;
  }

  @include respond(tab-port) {
    padding-block: 1.2rem;
  }
}

.menuButton {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: $color-dark-grey-secondary;
  text-transform: none !important;
  background-color: inherit;
  border: none;
  margin: 0.6rem 0;
  padding: 0;
  padding-left: 1.6rem;
  font-size: 1.6;
  line-height: 1.9rem;

  &:hover {
    color: $color-blue-primary;
    background-color: inherit;
  }

  @include respond(tab-port) {
    padding: 2rem 0 2rem 3.75rem;
    border-bottom: 2px solid $color-light;
    margin: 0;
  }
}

.password {
  width: fit-content;
  top: 60%;
  transform: translateY(-50%);
}


.animateSpin {
  animation: spin 1s linear infinite;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}