@import '../abstracts/variables';
@import '../abstracts/mixins';

.mantine-InputWrapper-root {
  margin-top: 1.6rem;
  padding: 0;
  border: none !important;
  background: none !important;
  border: 1px solid $color-dark-white;

  @include respond(tab-port) {
    margin-top: 2rem;
  }
}

.mantine-Input-input, .mantine-InputWrapper-label {
  font-size: 1.4rem !important;
  line-height: 1.9rem !important;

  @include respond(tab-port) {
    font-size: 1.6rem !important;
    line-height: 2.4rem !important;
  }
}

.mantine-Input-input {
  height: 4rem !important;
  font-weight: 400 !important;
  border-radius: 0 !important;
  border: 1px solid $color-dark-white !important;

  @include respond(tablet) {
    height: 4.5rem !important;
  }

  @include respond(tab-port) {
    height: 5rem !important;
  }
}

.mantine-InputWrapper-label {
  padding-bottom: 0.8rem !important;
  font-weight: 400 !important;
  color: $color-dark-grey-secondary !important;
}

.mantine-InputWrapper-error {
  font-size: 1.2rem !important;
  line-height: 1.6rem !important;

  @include respond(tab-port) {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
}

.mantine-InputWrapper-description {
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.4rem !important;

  @include respond(tab-port) {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
}

.mantine-InputWrapper-description {
  color: $color-dark-white !important;
}
