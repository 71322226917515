@import '../abstracts/variables';
@import '../abstracts/mixins';

.mantine-Checkbox-body {
  display: flex !important;
  align-items: center !important;
}

.mantine-Checkbox-label {
  padding-left: 1.25rem !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.4rem !important;
  color: $color-dark-grey-secondary !important;

  @include respond(tab-port) {
    font-size: 1.5rem !important;
    line-height: 1.8rem !important;
  }
}

.mantine-Anchor-root {
  color: $color-dark-grey-secondary !important;
}

.mantine-Checkbox-inner {
  & input {
    border-radius: 0 !important;
    border: 1px solid $color-dark-white !important;

    &:checked {
      background-color: $color-white !important;
    }
  }

  & svg {
    color: $color-dark-grey-secondary !important;
  }
}

.requiredCheckbox label:before {
  display: inline !important;
  content:"* " !important;
  color: #fa5252 !important;
}

.checkoutCheckboxWrapper {
  padding-block: 2.4rem !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 1.6rem !important;

  & .mantine-Checkbox-label {
    padding-left: 1.25rem !important;
    font-weight: 500 !important;
    font-size: 1.2rem !important;
    line-height: 1.6rem !important;
    color: $color-dark-grey-secondary !important;

    @include respond(tab-port) {
      font-size: 1.5rem !important;
      line-height: 1.8rem !important;
    }
  }

  @include respond(tab-port) {
    padding-block: 3rem !important;
  }
}
