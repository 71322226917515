.postList, .postsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.2rem;

  @include respond(tablet) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
    gap: 11px;
    width: 100%;
  }

  @media screen and (max-width: 490px) {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr)) !important;
  }

  @media screen and (max-width: 370px) {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr)) !important;
  }
}

.postsWrapper .styles_container__cfBuA:last-child, .postList .styles_container__cfBuA:last-child {
	@include respond(tablet) {
    display: none;
  }
}
